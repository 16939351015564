/** @format */

import React from "react";
import { getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import GlobalLayout from "../components/layouts/GlobalLayout";
import SiteHeader from "../components/SiteHeader/SiteHeader";
import Footer from "../components/Footer/Footer";
import HelmetTemplate from "../components/HelmetTemplate/HelmetTemplate";

import {
    StyledCennik,
    StyledLeftWrapper,
    StyledRightWrapper,
    StyledCennikImg,
} from "../components/Cennik/StyledCennik";

const Cennik = () => {
    const data = useStaticQuery(graphql`
        query Cennik {
            datoCmsCennik {
                naglowek
                lista {
                    element
                }
                listaCzerwona {
                    element
                }
                zdjecie {
                    gatsbyImageData(placeholder: TRACED_SVG)
                }
            }
        }
    `);
    const image = getImage(data.datoCmsCennik.zdjecie);
    
    return (
        <GlobalLayout>
            <HelmetTemplate
                title="Cennik"
                desc="Tutaj znajdziesz ceny obowiązujące w naszej placówce"
            />
            <SiteHeader title="Cennik" />
            <StyledCennik>
                <StyledLeftWrapper>
                    <h2>{data.datoCmsCennik.naglowek}</h2>
                    <ul>
                        {data.datoCmsCennik.lista.map(({ element }) => (
                            <li>{element}</li>
                        ))}
                    </ul>
                    <div>
                        {data.datoCmsCennik.listaCzerwona.map(({ element }) => (
                            <p>{element}</p>
                        ))}
                    </div>
                </StyledLeftWrapper>
                <StyledRightWrapper>
                    <StyledCennikImg image={image} alt="Pokój" />
                </StyledRightWrapper>
            </StyledCennik>
            <Footer />
        </GlobalLayout>
    );
};

export default Cennik;
