/** @format */

import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import {
    StyledFooter,
    StyledInfoWrapper,
    StyledCopyrightWrapper,
    StyledPhoneWrapper,
    StyledEmailWrapper,
    StyledInstagramWrapper,
    StyledFacebookWrapper,
} from "./StyledFooter";

import Instagram from "../../images/instagram.svg";
import Facebook from "../../images/facebook.svg";

const Footer = () => {
    const data = useStaticQuery(graphql`
        query footer {
            datoCmsStopka {
                telefon2
                telefon1
                instagramLink
                facebookLink
                email
                copyrightText
            }
        }
    `);
    return (
        <StyledFooter>
            <p>Przystanek na Leśnej</p>
            <StyledInfoWrapper>
                <StyledPhoneWrapper>
                    <p>
                        Telefon: <span>{data.datoCmsStopka.telefon1}</span>
                    </p>
                    <p>
                        <span>{data.datoCmsStopka.telefon2}</span>
                    </p>
                </StyledPhoneWrapper>
                <StyledEmailWrapper>
                    <p>
                        E-mail: <span>{data.datoCmsStopka.email}</span>
                    </p>
                    <p>
                        Wiecej informacji znajdziesz na stronie{" "}
                        <Link to="/kontakt">Kontakt</Link>
                    </p>
                </StyledEmailWrapper>
                <StyledInstagramWrapper>
                    <a href={data.datoCmsStopka.instagramLink}>
                        <Instagram />
                        <p>Instagram</p>
                    </a>
                </StyledInstagramWrapper>
                <StyledFacebookWrapper>
                    <a href={data.datoCmsStopka.facebookLink}>
                        <Facebook />
                        <p>Facebook</p>
                    </a>
                </StyledFacebookWrapper>
            </StyledInfoWrapper>
            <StyledCopyrightWrapper>
                <p>{data.datoCmsStopka.copyrightText}</p>
            </StyledCopyrightWrapper>
        </StyledFooter>
    );
};

export default Footer;
