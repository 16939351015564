/** @format */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import {
    StyledSiteHeader,
    StyledSiteHeaderImg,
    StyledHeaderText,
    StyledSvgImage,
} from "./StyledSiteHeader";
import Listek from "../../images/listek.svg";

const SiteHeader = ({ title }) => {
    const data = useStaticQuery(graphql`
        query NaglowekQuerySite {
            datoCmsNaglowek {
                glowneZdjecie {
                    gatsbyImageData(placeholder: TRACED_SVG)
                }
                drugaLinia
                pierwszaLinia
            }
        }
    `);
    const image = getImage(data.datoCmsNaglowek.glowneZdjecie);
    return (
        <StyledSiteHeader>
            <div>   
                <StyledSiteHeaderImg image={image} alt="Przystanek na Leśnej" />
                <StyledHeaderText>
                    {title ? (
                        <h1>{title}</h1>
                    ) : (
                        <>
                            <h1>{data.datoCmsNaglowek.pierwszaLinia}</h1>
                            <h1>{data.datoCmsNaglowek.drugaLinia}</h1>
                        </>
                    )}
                    <StyledSvgImage>
                        <Listek />
                    </StyledSvgImage>
                </StyledHeaderText>
            </div>
        </StyledSiteHeader>
    );
};

export default SiteHeader;
