import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledCennik = styled.section`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 110px 40px 60px 40px;
    max-width: 1580px;
    margin: 0 auto;
    @media only screen and (max-width: 1239px){
        padding-top: 60px;
    }
    @media only screen and (max-width: 477px){
        padding: 50px 20px 40px 20px;
    }
`;
export const StyledCennikImg = styled(GatsbyImage)`
    width: 100%;
    max-height: 676px;
    max-width: 450px;
    box-shadow: 13px 8px 17px 6px rgba(0, 0, 0, 0.42);
    border-radius: 22px;
`;
export const StyledLeftWrapper = styled.div`
    width: 50%;
    > h2 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 160.7%;
    }
    > ul {
        margin-top: 20px;
        margin-left: 24px;
        li{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 22px;
            line-height: 160.7%;
            padding: 5px;
        }
    }
    > div {
        margin-top: 10px;
        > p{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 160.7%;
            padding: 5px;
            position: relative;
            margin-left: 4px;
            &::before{
                content: '*';
                position: absolute;
                top: 3px;
                left: -5px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                color: #FF0606;
            }
        }
    }
    @media only screen and (max-width: 1481px){
        > h2{
            font-size: 28px;
        }
        > ul {
            li{
                font-size: 18px;
            }
        }
        > div {
            > p{
                font-size: 18px;
            }
        }
    }
    @media only screen and (max-width: 1239px){
        width: 100%;
        > ul {
            margin-top: 30px;
        }
    }
    @media only screen and (max-width: 477px){
        > h2{
            font-size: 22px;
        }
        > ul {
            li{
                font-size: 16px;
            }
        }
        > div {
            > p{
                font-size: 16px;
            }
        }
    }
`;
export const StyledRightWrapper = styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: 1481px){
        width: 40%;
    }
    @media only screen and (max-width: 1239px){
        display: none;
    }
`;