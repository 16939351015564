/** @format */

import styled from "styled-components";

export const StyledFooter = styled.footer`
    width: 100%;
    background-color: #E0E0E0;
    padding: 40px 40px 20px 40px;

    > p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 160.7%;
    }

    @media only screen and (max-width: 998px){
        > p {
            font-size: 20px;
        }
    }
    @media only screen and (max-width: 500px){
        padding: 30px 20px 20px 20px;
    }
`;

export const StyledInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    @media only screen and (max-width: 848px){
        flex-wrap: wrap;
        > div{
            width: 50%;
            padding: 10px 0;
        }
    }
    @media only screen and (max-width: 500px){
        > div{
            width: 100%;
        }
    }
`;

export const StyledCopyrightWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 30px;
    > p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 160.7%;
    }

    @media only screen and (max-width: 998px){
        > p {
            font-size: 12px;
        }
    }
`;

export const StyledPhoneWrapper = styled.div`
    > p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 160.7%;
        text-align: right;
        > span {
            color: #ff621f;
        }
    }
    @media only screen and (max-width: 998px){
        > p {
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 848px){
        > p {
            text-align: left;
        }
    }
`;

export const StyledEmailWrapper = styled.div`
    > p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 160.7%;
        > a {
            color: #ff621f;
            text-decoration: none;
        }
    }
    @media only screen and (max-width: 998px){
        > p {
            font-size: 16px;
        }
    }
`;

export const StyledInstagramWrapper = styled.div`
    display: flex;
    align-items: center;
    a {
        display: flex;
        align-items: center;
        color: #000;
        text-decoration: none;
        cursor: pointer;
        > p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 160.7%;
            margin-left: 10px;
        }
    }
    @media only screen and (max-width: 998px){
        > a {
            p {
                font-size: 16px;
            }
        }
    }
`;

export const StyledFacebookWrapper = styled.div`
    display: flex;
    align-items: center;
    a {
        display: flex;
        align-items: center;
        color: #000;
        text-decoration: none;
        cursor: pointer;
        > p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 160.7%;
            margin-left: 10px;
        }
    }
    > a {
        p {
            font-size: 16px;
        }
    }
`;
