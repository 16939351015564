/** @format */

import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledSiteHeader = styled.div`
    width: 100%;
    height: 384px;
    position: relative;
    > div{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    @media only screen and (max-width: 756px){
        height: 300px;
    }
    @media only screen and (max-width: 440px){
        height: 260px;
    }
`;

export const StyledSiteHeaderImg = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.84);
    }
`;

export const StyledHeaderText = styled.div`
    text-align: center;
    position: relative;
    z-index: 2;
    > h1 {
        color: #000;
        font-size: 64px;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        line-height: 1.2em;
    }
    @media only screen and (max-width: 1030px){
        > h1{
            font-size: 48px;
        }
    }
    @media only screen and (max-width: 756px){
        > h1{
            font-size: 38px;
        }
    }
    @media only screen and (max-width: 574px){
        > h1{
            font-size: 30px;
        }
    }
    @media only screen and (max-width: 440px){
        > h1{
            font-size: 26px;
        }
    }
    @media only screen and (max-width: 370px){
        > h1{
            font-size: 22px;
        }
    }
    @media only screen and (max-width: 330px){
        > h1{
            font-size: 20px;
        }
    }
`;

export const StyledSvgImage = styled.div`
    position: absolute;
    bottom: -140px;
    right: -160px;

    @media only screen and (max-width: 1030px){
        bottom: -100px;
        right: -130px;
        > svg {
            width: 200px;
            height: 200px;
        }
    }

    @media only screen and (max-width: 756px){
        bottom: -110px;
        right: -90px;
        > svg {
            width: 150px;
            height: 150px;
        }
    }

    @media only screen and (max-width: 756px){
        bottom: -70px;
        right: -65px;
        > svg {
            width: 100px;
            height: 100px;
        }
    }
    @media only screen and (max-width: 400px){
        bottom: -40px;
        right: -50px;
        > svg {
            width: 80px;
            height: 80px;
        }
    }
    @media only screen and (max-width: 300px){
        bottom: -40px;
        right: -40px;
        > svg {
            width: 70px;
            height: 70px;
        }
    }
`;
